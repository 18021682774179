<!--
 * @Author: SongYijie
 * @Date: 2020-05-07 14:20:12
 * @LastEditors: SongYijie
-->
<template>
  <div :class="this.isLandscape ? 'signLandscape' : 'sign'" >
		<div class="btn-wrap"	v-if="this.isLandscape">
			<p class="info">请务必使用正楷字体填写签名！</p>
			<p @click="handleBack">返回</p>
			<p @click="throttleSvae">确定</p>
			<p @click="clear">重写</p>
		</div>
		<div class="canvas-box">
			<vueSignature ref="signature" :sigOption="option" :disabled="disabled"></vueSignature> 
		</div>
		<div class="btn-wrap"	v-if="!this.isLandscape">
			<p class="info">
				<span>请</span>
				<span>务</span>
				<span>必</span>
				<span>使</span>
				<span>用</span>
				<span>正</span>
				<span>楷</span>
				<span>字</span>
				<span>体</span>
				<span>填</span>
				<span>写</span>
				<span>签</span>
				<span>名</span>
				<span>！</span>
			</p>
			<p @click="handleBack">
				<span>返</span>
				<span>回</span>
			</p>
			<p @click="throttleSvae">
				<span>确</span>
				<span>定</span>
			</p>
			<p @click="clear">
				<span>重</span>
				<span>写</span>
			</p>
		</div>
  </div>
</template>

<script>
import { throttle } from "@utils/common";
import { uploadSignImage } from "@api/individualBusiness";
import { Toast } from 'mint-ui';
import { canvasDataURL } from '@utils/uploadImg';
export default {
  name: "canvasSign",
  data() {
    return {
      option: {
				penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
			},
			disabled: false,
			isLandscape: false
    };
  },
  mounted() {
		this.renderResize();
		window.addEventListener("resize", this.renderResize, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.renderResize, false);
  },
  computed: {},
  methods: {
		throttleSvae: throttle(
			function() {
				this.save();
			}, 2000
		),
		renderResize() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (width > height) {
        this.isLandscape = true;
      } else {
        this.isLandscape = false;
			}
    },
    save() {
			let _this = this;
			if (_this.$refs.signature.isEmpty()) {
        Toast('请填写签名!');
        return;
      }
			let jpeg = _this.$refs.signature.save('image/jpeg');
			canvasDataURL(jpeg, {
				quality: 0.6,
				zoom: 0.4,
				isBase64: true,
				isLandscape: !this.isLandscape
			}, base64 => {
				let result = base64.split(',')[1];
				const imgData = {
					contractUrl: result
				}
				uploadSignImage(imgData).then(res => {
					if (res.code === 200) {
						_this.$router.replace('/individual-business');
					} else {
						Toast(res.message);
					}
				})
			})
		},
		clear() {
			let _this = this;
			_this.$refs.signature.clear();
		},
		handleBack() {
      this.$router.back(-1);
    },
  }
};
</script>

<style lang="less" scoped>
.sign, .signLandscape {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  padding: 6px 16px 16px;
}
.canvas-box {
	width: 100%;
	height: 90%;
	border: 4px solid #cccccc;
	box-sizing: border-box;
	position: relative;
	&::before {
		content: '签名';
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		font-size: 14.4vmax;
		opacity: 0.1;
		transform: translate(-50%, -50%);
		white-space: nowrap;
		pointer-events: none;
	}
}
.btn-wrap {
	height: 10%;
	display: flex;
	align-items: center;
	text-align: center;
	> p {
		margin: 0;
		height: 80%;
		display: flex;
		align-items: center;
		text-align: center;
		padding: 0 12px;
		white-space: nowrap;
		margin-left: 5.3333vw;
		border: 0.2667vw solid #cccccc;
		color: #333333;
    border-radius: 1.3333vw;
	}
	.info {
		border: none;
		flex-grow: 1;
		margin-left: 0;
	}
}

.sign {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	.canvas-box {
		width: 96%;
		height: 100%;
		&::before {
			transform-origin: 50% 50%;
			transform: rotate(90deg) translate(-25%, 75%);
		}
	}
	.btn-wrap {
		width: 4%;
		height: 100%;
		align-items: center;
		flex-wrap: wrap;
		margin-left: 1.6vw;
		p {
			// width: auto;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			font-size: 0;
			justify-content: flex-start;
			margin: 0;
			padding: 4px;
			span {
				display: block;
				transform: rotate(90deg);
				font-size: 3vw;
			}
		} 
	}
}
</style>
